import {
  elevate_l,
  elevate_s,
  elevate_xl,
  shadow_l,
  theme,
} from '@faxi/web-component-library';
import {
  flex,
  fontSize,
  laptop,
  marginChildren,
  mobile,
  padding,
  phablet,
  pxToRem,
  size,
  tablet,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const PageLayout = styled.div`
  ${flex('column', 'flex-start')};
  padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_64};
  overflow: auto;
  height: 100%;

  ${phablet(css`
    padding: ${theme.sizes.SIZE_20};
  `)};

  &.dashboard,
  &.reports,
  &.predefined-shifts,
  &.gamification--blue,
  &.sustainability,
  &.email-notifications,
  &.bookings-page {
    background-color: var(--BACKGROUND_2_1);
  }

  &.reports {
    padding-top: 0;
    height: 100%;
    overflow: unset;

    .kinto-page__heading {
      margin-top: ${theme.sizes.SIZE_32};
    }

    &--is-scrolled {
      .kinto-page__header {
        ${shadow_l};
        background-color: white;
        z-index: 2;
        transition: background-color 200ms;
      }
    }

    .kinto-page__header {
      position: sticky;
      top: 0;
      transition: background-color 400ms;

      ${laptop(css`
        .kinto-filter-button {
          &__date-picker {
            margin-right: ${theme.sizes.SIZE_4};
          }
        }
      `)}

      @media (max-width: 1250px) {
        .kinto-page__header__commands {
          align-items: baseline;

          > *:first-child {
            gap: ${theme.sizes.SIZE_4};
            ${flex('column', 'center', 'center')}
            margin-bottom:  ${theme.sizes.SIZE_8};

            .wcl-date-picker {
              order: -1;
            }
          }
        }
      }

      ${tablet(css`
        ${flex('column')}
      `)}

      ${phablet(css`
        .kinto-page__header__commands {
          ${flex('row', undefined, 'flex-end')};
          gap: ${theme.sizes.SIZE_24};

          > *:first-child {
            ${flex('column', 'flex-start', 'flex-start')}
          }
        }
      `)}

      ${mobile(css`
        .kinto-page__header__commands {
          align-items: baseline;
        }
      `)}

      .wcl-radio-group {
        align-items: center;

        .wcl-radio-button__label {
          height: ${theme.sizes.SIZE_32};
          padding: ${theme.sizes.SIZE_8};
        }
      }
    }
  }

  &.gamification {
    ${marginChildren(`0 0 ${theme.sizes.SIZE_32} 0`)};
    overflow: hidden;
  }

  &.events {
    .glow-scroll {
      height: fit-content;
    }
  }

  .kinto-page {
    &__heading {
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__header {
      ${flex('column', 'center', 'stretch')};
      margin-bottom: ${theme.sizes.SIZE_20};
      position: relative;
      ${marginChildren(`0 0 ${theme.sizes.SIZE_20} 0`)};

      &--row {
        ${flex('row', 'space-between', 'center')};
        ${marginChildren('0')};
      }

      ${phablet(css`
        ${flex('column', 'center', 'inherit')};
      `)};

      &__commands {
        ${flex('row', 'flex-end', 'center')};
        ${marginChildren(`0 ${theme.sizes.SIZE_20} 0 0`)};
        margin-left: auto;

        ${laptop(css`
          ${marginChildren(`0 ${theme.sizes.SIZE_8} 0 0`)};
        `)}

        ${phablet(
          css`
            ${flex('column', 'center', 'flex-end')};
            ${marginChildren(`0 0 ${theme.sizes.SIZE_8} 0`)};
          `
        )};
      }

      &__actions {
        ${flex('row', 'space-between', 'center')};
        margin-bottom: ${theme.sizes.SIZE_24};

        &__close-btn {
          margin-left: auto;

          svg {
            ${size('24px')};
          }
        }
      }

      &__reports-page {
        margin: 0 -${theme.sizes.SIZE_64} ${theme.sizes.SIZE_24};
        padding: 0 ${theme.sizes.SIZE_64} ${theme.sizes.SIZE_8};
      }
    }

    &__body {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(34%, 1fr));
      gap: ${theme.sizes.SIZE_48};

      @media (max-width: 1400px) {
        display: grid;
        grid-template-columns: 100%;
      }

      ${mobile(css`
        gap: ${theme.sizes.SIZE_20};
      `)};

      &__full {
        @media (min-width: 1400px) {
          grid-column: span 2;
        }
      }

      &__report-banner {
        background: transparent;
        display: flex;
        flex-direction: column;
        ${marginChildren(`0 0 ${theme.sizes.SIZE_24} 0`)};

        ${phablet(css`
          ${marginChildren(`0 0 ${theme.sizes.SIZE_20} 0`)};
        `)}

        .report-banner {
          display: flex;
          ${marginChildren(`0 ${theme.sizes.SIZE_24} 0 0`)};

          ${phablet(css`
            flex-direction: column;
            ${marginChildren(`0 0 ${theme.sizes.SIZE_20} 0`)};
          `)}

          &__report {
            flex: 0 1 100%;
            border-radius: ${theme.sizes.SIZE_8};
            ${elevate_xl}
          }
        }
      }
    }
  }

  .kinto-custom-information {
    &__action-button {
      margin-top: ${theme.sizes.SIZE_32};

      ${phablet(css`
        width: 100%;
      `)};
    }
  }

  .predefined-shifts-list-form {
    background-color: var(--BACKGROUND_1_1);
    border-radius: ${theme.sizes.SIZE_8};

    &__actions {
      margin: ${`${theme.sizes.SIZE_32} ${theme.sizes.SIZE_48} ${theme.sizes.SIZE_46}`};
    }

    &__check-all {
      ${flex('row', 'flex-start', 'center')};
      border-left: ${theme.sizes.SIZE_4} solid transparent;
      padding: ${`${theme.sizes.SIZE_32} ${theme.sizes.SIZE_46} ${theme.sizes.SIZE_12}`};
      gap: ${theme.sizes.SIZE_16};

      ${laptop(css`
        flex-wrap: wrap;
        padding: ${`${theme.sizes.SIZE_16} ${theme.sizes.SIZE_20}`};
      `)};

      &__checkbox {
        flex: 1 1 30%;
        justify-content: flex-end;

        ${tablet(css`
          flex: unset;
        `)};
      }

      &__dummy {
        flex: 0 0 30%;

        ${laptop(css`
          display: none;
        `)};
      }

      &__delete {
        margin-left: auto;
        margin-right: ${theme.sizes.SIZE_16};
      }
    }

    &__add-new-shift {
      margin: ${`${theme.sizes.SIZE_32} ${theme.sizes.SIZE_48} 0`};
    }
  }

  .people {
    &__table {
      .wcl-table-container__close-actions {
        ${size(theme.sizes.SIZE_24)};

        margin: auto 0;
        min-height: unset;

        .wcl-icon {
          ${size('100%')};
        }
      }
    }

    &__error {
      padding-top: ${theme.sizes.SIZE_48};
      display: grid;
      grid-template-columns: 1fr ${theme.sizes.SIZE_376} ${theme.sizes.SIZE_376} 1fr;

      &__message {
        ${padding(`${theme.sizes.SIZE_48}`, `${theme.sizes.SIZE_32}`)};
        grid-column: 2;

        &__title {
          padding-bottom: ${theme.sizes.SIZE_20};
          color: var(--PRIMARY_1_1);
          font-weight: 600;
        }

        &__body {
          display: block;
          color: var(--SHADE_2_1);
        }

        &__button {
          margin-top: ${theme.sizes.SIZE_20};
          font-weight: 500;
          ${fontSize(theme.fontSizes.FONT_16)};

          &__icon {
            margin-right: ${theme.sizes.SIZE_10};
            padding-top: ${theme.sizes.SIZE_2};
          }

          ${mobile(css`
            ${padding(`${theme.sizes.SIZE_16}`, `${theme.sizes.SIZE_32}`)};
            ${fontSize(theme.fontSizes.FONT_14)};
          `)};
        }
      }

      &__illustration {
        grid-column: 3;

        &__image {
          width: ${pxToRem(theme.sizes.SIZE_376)};
        }
      }

      ${phablet(css`
        align-self: center;
        grid-template-columns: 1fr;
        width: ${pxToRem(theme.sizes.SIZE_376)};

        &__message {
          grid-column: 1;
          order: 2;
          text-align: center;
        }
        &__illustration {
          margin: 0 auto;
          grid-column: 1;
          order: 1;
        }
      `)};

      ${mobile(css`
        &__message {
          padding: ${theme.sizes.SIZE_32};

          &__title {
            ${fontSize(theme.fontSizes.FONT_20, theme.fontSizes.FONT_28)};
          }
        }

        &__illustration {
          &__image {
            width: ${pxToRem(theme.sizes.SIZE_248)};
          }
        }
      `)};
    }
  }

  .table-actions {
    display: flex;
    align-items: center;

    &__btns {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__divider {
      border-right: 1px solid var(--BACKGROUND_1_1);
      height: ${pxToRem(theme.sizes.SIZE_24)};
      margin-left: ${theme.sizes.SIZE_24};
      margin-right: ${theme.sizes.SIZE_16};
    }

    &__edit-btn-ref {
      position: relative;
      margin: auto;
    }

    &__edit-btn {
      margin-right: ${theme.sizes.SIZE_24};
      color: var(--BACKGROUND_1_1);

      &:hover {
        color: var(--BACKGROUND_1_1);
      }
    }

    &__times-btn {
      color: var(--BACKGROUND_1_1);

      &:hover {
        color: var(--BACKGROUND_1_1);
      }
    }

    &__edit-modal {
      background-color: transparent;
      position: absolute;
      height: fit-content;
      margin-top: ${theme.sizes.SIZE_48};

      .modal {
        ${elevate_s};
      }

      &__action-btn {
        color: var(--SHADE_1_2);

        &--times {
          margin-left: -${'5px'};
        }
        &--redo {
          margin-bottom: 0;
        }
      }
    }

    &__shift-settings-modal {
      color: var(--SHADE_1_1);
      .wcl-modal__content {
        background-color: var(--BACKGROUND_2_1);
        padding: ${theme.sizes.SIZE_40} ${theme.sizes.SIZE_32}
          ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_32};
      }
      .wcl-modal__header__close {
        margin-left: 0;
        .button__icon {
          > div {
            ${size('28px')};
            ${fontSize(theme.fontSizes.FONT_28)};
          }
        }
      }

      &__shift-settings {
        &__title {
          color: var(--PRIMARY_1_1);
          font-weight: 600;
          ${fontSize(theme.fontSizes.FONT_20)};
          margin-bottom: ${theme.sizes.SIZE_32};
        }

        &__back-btn {
          margin-bottom: ${theme.sizes.SIZE_12};
        }
      }

      &__pick-shift {
        display: flex;
        flex-direction: column;
        border: 1px solid var(--SHADE_1_1);
        border-radius: ${theme.sizes.SIZE_8};
        ${elevate_l};
        padding: ${theme.sizes.SIZE_16};
        background-color: var(--BACKGROUND_1_1);

        &__header {
          display: flex;
          justify-content: space-between;
          margin-bottom: ${theme.sizes.SIZE_12};
          align-items: baseline;
        }

        &__title {
          color: var(--SHADE_1_1);
          font-weight: 600;
          margin-bottom: ${theme.sizes.SIZE_24};

          &--selected-shift {
            color: var(--SHADE_1_3);
            ${fontSize(theme.sizes.SIZE_12)};
            margin-bottom: 0;
          }
        }
      }

      &__time-schedule {
        display: flex;
        align-items: center;
        margin-bottom: ${theme.sizes.SIZE_32};

        &__icon {
          margin-right: ${theme.sizes.SIZE_20};
        }

        &__btn {
          > div:last-of-type {
            color: var(--SHADE_1_1);
            font-weight: 400;
            ${fontSize(theme.fontSizes.FONT_14)};
          }
        }
      }

      &__save-btn {
        align-self: flex-end;
      }

      &__saved-shifts {
        &__actions {
          display: flex;
          justify-content: space-between;
          margin-top: ${theme.sizes.SIZE_40};
        }
      }
    }
  }

  .total-reports-data {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: ${theme.sizes.SIZE_24};
    gap: ${theme.sizes.SIZE_24};

    @media (max-width: 1400px) {
      grid-template-columns: repeat(2, 1fr);
    }

    ${phablet(css`
      margin-bottom: ${theme.sizes.SIZE_24};
    `)};

    ${mobile(css`
      grid-template-columns: auto;
      margin-bottom: ${theme.sizes.SIZE_20};
      gap: ${theme.sizes.SIZE_8};
    `)}
  }
`;

export default PageLayout;
