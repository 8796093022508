import {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  FC,
  Fragment,
} from 'react';
import {
  Button,
  Checkbox,
  Heading,
  Icon,
  getColor,
  useCallbackRef,
  useFormButtons,
  useUtilities,
} from '@faxi/web-component-library';
import {
  Form,
  FormRef,
  useFormRefValues,
  validators,
  FieldArray,
  FieldArrayRef,
} from '@faxi/web-form';

import { useTranslation } from 'react-i18next';

import { UserContext } from 'store';
import { apiPredefinedShifts } from 'modules';
import { Shift } from 'models';
import regex from 'validation/regex';
import specific from 'validation/validators/specific';
import { calculateDurationTime, newEndTime, newShiftName } from './utils';
import { SettingsTabEmptyScreen, FormActions } from 'components';
import ShiftRow from './components/ShiftRow/ShiftRow.component';
import { PanelContainer } from 'Global.styles';
import { PageLayout } from 'components/_layouts';
import classNames from 'classnames';

const PredefinedShiftsTab: FC = (): JSX.Element => {
  const [shifts, setShifts] = useState<Shift[]>();

  const { communityId } = useContext(UserContext);

  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const { t } = useTranslation();

  const [form, formRef] = useCallbackRef<FormRef>();
  const [fieldArray, fieldArrayRef] = useCallbackRef<FieldArrayRef>();

  const [deletedShifts, setDeletedShifts] = useState<number[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [checkboxes, setCheckboxes] = useState<
    { id: number | undefined; index: number }[]
  >([]);
  const [updateForm, setUpdateForm] = useState(false);

  const formValues = useFormRefValues(form, 'shifts')?.shifts;

  const initialData = useMemo(() => ({ shifts }), [shifts]);

  const [FormButtons] = useFormButtons({
    submitLabel: t('CommuteDetails_saving_message'),
    cancelLabel: t('cancel'),
    loadingOverlaySelector: '.kinto-page',
  });

  const getShifts = useCallback(async () => {
    if (!communityId) {
      return;
    }

    try {
      showOverlay('.kinto-page');

      const { shifts } = await apiPredefinedShifts.getShifts(communityId);

      setShifts(shifts);
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.kinto-page');
    }
  }, [communityId, hideOverlay, showOverlay]);

  const handleOnDeleteShift = useCallback(
    (deletingInd?: number) => {
      const removing = formValues.reduce(
        (acc: number[], _value: Shift, idx: number) => {
          if (checkboxes.some(({ index }) => index === idx)) acc.push(idx);
          return acc;
        },
        []
      );

      fieldArray.remove([...removing, deletingInd]);

      const tmp = checkboxes
        .filter(({ id }) => id !== undefined)
        .map(({ id }) => id) as number[];

      setDeletedShifts((old) => [...old, ...tmp]);
      const singleDeleted = fieldArray.fields?.[deletingInd!]?.value.id;

      if (singleDeleted) {
        setDeletedShifts((old) => [...old, singleDeleted]);
      }

      setCheckboxes([]);
      setAllSelected(false);
    },
    [checkboxes, fieldArray, formValues]
  );

  const handleSubmit = useCallback(
    async (data: any) => {
      if (!communityId) {
        return;
      }

      const tmp = (data.shifts as Shift[]).map(
        ({ id, name, start_time, end_time }) => ({
          id,
          name: name.trim(),
          start_time: start_time.substring(0, 5),
          end_time: end_time.substring(0, 5),
          duration: calculateDurationTime(start_time, end_time),
        })
      );

      const updatedDate = new FormData();
      updatedDate.append('shifts', JSON.stringify(tmp));

      try {
        if (deletedShifts.length !== 0) {
          await apiPredefinedShifts.deleteMultipleShits(
            communityId,
            deletedShifts
          );
        }

        if (tmp.length !== 0) {
          await apiPredefinedShifts.updateBatchShifts(communityId, updatedDate);
        }

        setDeletedShifts([]);
        await getShifts();

        setUpdateForm(true);
        showSnackBar({
          actionButtonText: t('dismiss'),
          text: t('changes_saved'),
          variant: 'success',
        });
      } catch (e) {
        console.error(e);
      }
    },
    [communityId, deletedShifts, getShifts, showSnackBar, t]
  );

  const shiftNameValidator = useMemo(
    () => (index: number) =>
      [
        validators.general.regex(
          regex.customInputLabelAndShiftNameRegex,
          t('validation-field_name', {
            fieldname: t('predefined_shifts-shift_name').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          50,
          t('validation-field_validation_max_length', {
            fieldname: t('predefined_shifts-shift_name').toLowerCase(),
            number: '50',
          })
        ),
        specific.shiftNameDuplicate(
          t('validation-unique_name', {
            fieldname: t('predefined_shifts-shift_name').toLowerCase(),
          }),
          index
        ),
      ],
    [t]
  );

  const validations = useMemo(
    () => ({
      shiftName: shiftNameValidator,
      start_time: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('predefined_shifts-start_time'),
          })
        ),
      ],
      end_time: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('predefined_shifts-end_time'),
          })
        ),
      ],
    }),
    [shiftNameValidator, t]
  );

  const noShifts = useMemo(
    () =>
      fieldArray?.fields.length === 0 &&
      deletedShifts.length === 0 &&
      shifts?.length === 0,
    [deletedShifts, fieldArray, shifts]
  );

  useEffect(() => {
    if (allSelected) {
      setCheckboxes(
        form?.fields.shifts.value.map((shift: Shift, index: number) => ({
          id: shift.id || undefined,
          index: index,
        }))
      );
    } else {
      setCheckboxes([]);
    }
  }, [allSelected, form]);

  useEffect(() => {
    getShifts();
  }, [getShifts, communityId]);

  useEffect(() => {
    if (!form || !updateForm) return;

    form.updateValueField('shifts', shifts);

    setUpdateForm(false);
  }, [shifts, form, updateForm]);

  return (
    <PageLayout className={classNames('kinto-page', 'predefined-shifts')}>
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('predefined_shifts-predefined_shifts')}
      </Heading>

      {!shifts ? (
        <Fragment />
      ) : (
        <Form
          onSubmit={handleSubmit}
          ref={formRef}
          strictValidation={false}
          initialData={initialData}
          className="predefined-shifts-list-form"
        >
          {/* FIELD ARRAY */}
          <FieldArray name="shifts" ref={fieldArrayRef}>
            {({ fields, add }) => (
              <Fragment>
                {/* SELECT ALL CHECKBOX */}
                {formValues?.length !== 0 && (
                  <div className="predefined-shifts-list-form__check-all">
                    <Checkbox
                      label={t('global-button_select_all')}
                      className="predefined-shifts-list-form__check-all__checkbox"
                      checked={allSelected}
                      onChange={() => setAllSelected(!allSelected)}
                    />

                    <div className="predefined-shifts-list-form__check-all__dummy" />

                    {checkboxes.length > 0 && (
                      <Button
                        icon={<Icon name="trash-can" />}
                        variant="delete-ghost"
                        iconPosition="right"
                        className="predefined-shifts-list-form__check-all__delete"
                        type="button"
                        onClick={() => {
                          handleOnDeleteShift();
                        }}
                      >
                        {t('predefined_shifts-delete_selected')}
                      </Button>
                    )}
                  </div>
                )}

                {/* FIELDS */}
                {fields?.map(
                  ({ name, value: { is_new, name: shiftName } }, index) => (
                    <ShiftRow
                      key={name}
                      name={name}
                      shiftsInfo={formValues}
                      index={index}
                      shiftName={shiftName}
                      checkbox={checkboxes.some(({ index: i }) => i === index)}
                      fieldArrayRef={fieldArray}
                      removeShift={(i: number) => {
                        setDeletedShifts((old) => [...old, i]);
                      }}
                      setCheckbox={() => {
                        setCheckboxes((old) => {
                          if (old.some(({ index: i }) => i === index)) {
                            return old.filter(({ index: i }) => i !== index);
                          } else {
                            return [
                              ...old,
                              {
                                id: formValues?.[index].id,
                                index,
                              },
                            ];
                          }
                        });
                      }}
                      validations={{
                        name: validations.shiftName(index),
                        start_time: validations.start_time,
                        end_time: validations.end_time,
                      }}
                      is_new={is_new}
                    >
                      <Button
                        icon={<Icon name="trash-can" />}
                        iconPosition="right"
                        aria-label={t('accessibility-button_delete', {
                          name: shifts[index]?.name,
                        })}
                        variant="delete-ghost"
                        className="kinto-shift__delete-btn"
                        type="button"
                        onClick={() => {
                          handleOnDeleteShift(index);
                        }}
                      />
                    </ShiftRow>
                  )
                )}

                {!noShifts && (
                  <Button
                    variant="outline"
                    icon={<Icon name="plus" />}
                    type="button"
                    iconPosition="left"
                    className="predefined-shifts-list-form__add-new-shift"
                    onClick={() => {
                      const last = formValues[formValues.length - 1];
                      add({
                        name: newShiftName(formValues),
                        start_time: last?.end_time.substring(0, 5) || '09:00',
                        end_time: newEndTime(last),
                        duration: 8,
                        is_new: true,
                      });
                    }}
                  >
                    {t('predefined_shifts-button_add_new_shift')}
                  </Button>
                )}
              </Fragment>
            )}
          </FieldArray>

          {noShifts ? (
            <PanelContainer>
              <SettingsTabEmptyScreen
                iconName="clock"
                title={t('predefined_shifts-empty_state_title_add_shifts')}
                contentText={t(
                  'predefined_shifts-body_add_all_the_shifts_available_in_your_workplace'
                )}
                buttonLabel={t('predefined_shifts-button_add_shift')}
                buttonCallback={() => {
                  fieldArray.add({
                    name: `${t('shift_settings-title_shift')} 1`,
                    start_time: '09:00',
                    end_time: '17:00',
                    duration: 8,
                    is_new: true,
                  });
                }}
              />
            </PanelContainer>
          ) : (
            // ACTIONS
            <FormActions className="predefined-shifts-list-form__actions">
              {checkboxes.length === 0 && (
                <FormButtons.Submit
                  disabled={!form?.isFormChanged() || !form.syncFormValid}
                />
              )}
            </FormActions>
          )}
        </Form>
      )}
    </PageLayout>
  );
};

export default PredefinedShiftsTab;
