import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import {
  Breadcrumbs,
  Button,
  Image,
  PAGE_LIMIT,
  Select,
  SelectOption,
  SortOption,
  SORT_OPTIONS,
  Table,
  Tabs,
} from '@faxi/web-component-library';
import { FiltersModal, Icon, InputField } from 'components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useOptions from './useOptions';
import { apiReports, LeaderboardGetParamType } from 'modules';
import { DepotFilter, LeaderboardData } from 'models';
import { NoData } from 'Global.styles';
import { useTablePagination } from 'hooks';
import { UserContext } from 'store';
import { FiltersButton } from 'components';
import { Filter } from 'components/_modals/FiltersModal/FiltersModal.component';
import { capitalizeFirstLetter, JOURNEY_DIRECTIONS } from 'utils';

import * as Styled from './Leaderboards.styles';

const itemsKey = ['leaguetable', 'leaguetable-test'];

type LeaderboardType = 'points' | 'journeys';

const INITIAL_LEADERBOARD_SORT = {
  sortBy: 'balance',
  sortDirection: SORT_OPTIONS.DSC,
} as SortOption<LeaderboardData>;

const Leaderboards = () => {
  const {
    communityId,
    userReady,
    userPreferences: { unit },
  } = useContext(UserContext);

  const { t } = useTranslation();

  const { organisationId } = useParams() as { organisationId: string };

  const [leaderboardType, setLeaderboardType] =
    useState<LeaderboardType>('points');

  const [directionsFilter, setDirectionsFilter] = useState([]);
  const [directionsFiltesModalOpen, setDirectionsFiltesModalOpen] =
    useState(false);

  const [leaderboardTypeParam, setLeaderboardTypeParam] =
    useState<LeaderboardGetParamType>('point');

  const [journeyLeaderboardDateFilter, setJourneyLeaderboardDateFilter] =
    useState('total');

  // created leaderboards from points page
  const [depotFilters, setDepotFilters] = useState<DepotFilter[]>([]);
  const [selectedDepotFilter, setSelectedDepotFilter] = useState<DepotFilter>();

  const filterBtnRef = useRef<HTMLButtonElement>(null);

  const { finalOptions } = useOptions({
    pointsActive: leaderboardType === 'points',
    depotFilters,
  });

  const breadcrumbsLinks = useMemo(
    () => [
      {
        id: 'gamification-link',
        text: t('global-gamification'),
        href: `/community/${organisationId}/admin/gamification`,
      },
      {
        id: 'leaderboards',
        text: t('leaderboards'),
        href: `/community/${organisationId}/admin/gamification/leaderboards`,
      },
    ],
    [t, organisationId]
  );

  const translationKeys = useMemo(
    () =>
      ({
        position: t('position_leaderboard'),
        name: t('global-name'),
        pdist: t('kilometers_saved'),
        avg_passengers: t('average_passengers'),
        jc: t('number_of_journeys'),
        balance: t('leaderboard-title_number_of_points'),
      } as Record<Partial<keyof LeaderboardData>, string>),
    [t]
  );

  const filters = useMemo<Filter[]>(
    () => [
      {
        legendLabel: t('mJourneys'),
        name: 'directions',
        checkboxes: [
          {
            value: 'to-office',
            label: capitalizeFirstLetter(t(JOURNEY_DIRECTIONS['to-office'])),
            id: 'filter_reports_to-office',
          },
          {
            value: 'to-home',
            label: capitalizeFirstLetter(t(JOURNEY_DIRECTIONS['to-home'])),
            id: 'filter_reports_to-home',
          },
          {
            value: 'to-event',
            label: capitalizeFirstLetter(t(JOURNEY_DIRECTIONS['to-event'])),
            id: 'filter_reports_to-event',
          },
          {
            value: 'to-custom',
            label: capitalizeFirstLetter(t(JOURNEY_DIRECTIONS['to-custom'])),
            id: 'filter_reports_to-custom',
          },
        ],
      },
    ],
    [t]
  );

  const tabsOptions = useMemo<{ label: string; value: LeaderboardType }[]>(
    () => [
      { label: t('leaderboard-title_number_of_points'), value: 'points' },
      { label: t('number_of_journeys'), value: 'journeys' },
    ],
    [t]
  );

  const journeysLeaderboardSelectTimeOptions = useMemo(
    () => [
      { label: t('total'), value: 'total' },
      { label: t('statistics_this_month'), value: 'current_month' },
    ],
    [t]
  );

  const {
    data,
    count,
    search,
    totalPages,
    totalCount,
    currentPage,
    activeColumnSort,
    setCount,
    onSearchChange,
    setCurrentPage,
    setActiveColumnSort,
  } = useTablePagination<LeaderboardData, 'leaguetable'>({
    itemsKey,
    totalKey: ['total', 'total-test'],
    condition: !!(communityId && userReady),
    initialSortBy: INITIAL_LEADERBOARD_SORT.sortBy,
    initialSortDirection: INITIAL_LEADERBOARD_SORT.sortDirection,
    deps: [communityId, leaderboardType],
    resetDeps: [
      leaderboardType,
      communityId,
      leaderboardTypeParam,
      journeyLeaderboardDateFilter,
      directionsFilter,
    ],
    customErrorMessage: t('no_members_in_community'),
    applyQueryParams: false,
    onDataLoad: (data) => {
      setDepotFilters(data.filters);
    },
    mappingFunction: async (data: LeaderboardData[]) => {
      return data?.map(
        ({
          position,
          image_url,
          first_name,
          last_name,
          pdist,
          jc,
          istest,
          avg_passengers,
          screen_name,
          balance,
        }) => ({
          id: `${leaderboardType}_${screen_name}`,
          position: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {position}
            </span>
          ),
          name: (
            <div className="table-data-with-image">
              <Image
                className="profile-img"
                src={image_url || ''}
                fallbackUrl="/assets/svg/user_circle_placeholder.svg"
                alt={t('user_profile_picture', { user: first_name })}
              />
              <span className={classNames({ 'kinto-test-data': istest })}>
                {first_name || last_name
                  ? [first_name, last_name].join(' ').trim()
                  : '-'}
              </span>
            </div>
          ),
          pdist: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {pdist}
            </span>
          ),
          avg_passengers: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {avg_passengers || '-'}
            </span>
          ),
          jc: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {jc || '0'}
            </span>
          ),
          balance: (
            <span className={classNames({ 'kinto-test-data': istest })}>
              {balance || '0'}
            </span>
          ),
        })
      ) as any;
    },
    apiRequest: (
      count: number,
      offset: number,
      search: string,
      sort_by,
      sort_direction,
      cancelConfig
    ): any =>
      apiReports.leaguetableReport({
        oid: communityId!,
        unit,
        count,
        offset,
        search,
        directions:
          leaderboardType === 'journeys'
            ? directionsFilter.join(',')
            : undefined,
        type: leaderboardType === 'points' ? 'point' : leaderboardTypeParam,
        ...(leaderboardType === 'points'
          ? !Number(leaderboardTypeParam)
            ? { period: 'total' }
            : {
                from: selectedDepotFilter?.data.from,
                to: selectedDepotFilter?.data.to,
              }
          : { period: journeyLeaderboardDateFilter }),
        config: cancelConfig,
        sort_by,
        sort_direction,
      }),
  });

  const handleOnColumnSort = useCallback(
    (sort: SortOption<LeaderboardData>) => {
      const { sortBy, sortDirection } = sort;
      setActiveColumnSort(sortBy, sortDirection);
    },
    [setActiveColumnSort]
  );

  return (
    <Styled.Leaderboards>
      <Breadcrumbs
        tabIndex={0}
        aria-label="breadcrumbs"
        crumbs={breadcrumbsLinks}
        className="leaderboards__breadcrumbs"
      />

      <Tabs
        className="leaderboards__tabs"
        tabs={tabsOptions}
        onChange={(value) => {
          setLeaderboardType(value as LeaderboardType);
          setLeaderboardTypeParam(value === 'points' ? 'point' : 'all');
          setJourneyLeaderboardDateFilter('total');
          setDirectionsFilter([]);
        }}
        value={leaderboardType}
      />

      <div className="leaderboards__filters">
        {leaderboardType !== 'points' && (
          <InputField
            value={search}
            onChange={onSearchChange}
            prefixIcon={<Icon name="magnifying-glass" />}
            placeholder={t('selgroup_search')}
            {...(search && {
              suffixIcon: (
                <Button
                  variant="ghost"
                  aria-label={t('delete_input')}
                  icon={<Icon name="xmark" />}
                  onClick={() => {
                    onSearchChange('');
                  }}
                />
              ),
            })}
          />
        )}
        <Select
          value={leaderboardTypeParam}
          options={finalOptions}
          onChange={(opt) => {
            if (leaderboardType === 'points') {
              setSelectedDepotFilter(
                depotFilters.find((el) => el.id === +opt.value)
              );
            }
            setLeaderboardTypeParam(opt.value as LeaderboardGetParamType);
          }}
        />
        {leaderboardType !== 'points' && (
          <>
            <Select
              options={journeysLeaderboardSelectTimeOptions}
              value={journeyLeaderboardDateFilter}
              onChange={(opt) => setJourneyLeaderboardDateFilter(opt.value)}
            />
            <FiltersButton
              activeFiltersCount={directionsFilter.length}
              onClick={() => setDirectionsFiltesModalOpen(true)}
              buttonRef={filterBtnRef}
            />
          </>
        )}
      </div>

      <Table<LeaderboardData>
        tableData={data}
        tableId="leaderboard-table"
        noDataPlaceholder={
          <NoData className="kinto-no-data">
            {t('search_no_results_found')}
          </NoData>
        }
        translationKeys={translationKeys}
        excludeSortColumns={[
          'name',
          'jc',
          'position',
          'pdist',
          'avg_passengers',
        ]}
        initialSort={activeColumnSort as SortOption<LeaderboardData>}
        onColumnSortClicked={handleOnColumnSort}
        excludeColumns={
          [
            'id',
            'last_name',
            'pc',
            'screen_name',
            'distance_saved',
            'tspic',
            'upc',
            'user_id',
          ].concat(
            leaderboardTypeParam === 'driving' ? [] : ['avg_passengers'],
            leaderboardTypeParam !== 'all' ? [] : ['pdist'],
            leaderboardType === 'points' || !!Number(leaderboardType)
              ? ['pdist', 'jc']
              : ['balance']
          ) as (keyof LeaderboardData)[]
        }
        perPageLabel={t('per_page')}
        chevronBtnAriaLabel={t('per_page')}
        perPagePlaceholder={t('per_page')}
        pageSelectorAriaLeftLabel={t('accessibility-button_previous_page')}
        pageSelectorAriaRightLabel={t('accessibility-button_next_page')}
        paginationData={{
          limit: count,
          totalPages,
          totalCount,
          currentPage,
        }}
        goToPageInputProps={{ placeholder: t('global-go_to_page') }}
        onPageChange={setCurrentPage}
        onLimitChange={(data: SelectOption) => {
          setCount(+data.value as keyof typeof PAGE_LIMIT);
        }}
      />

      {directionsFiltesModalOpen && (
        <FiltersModal
          onClose={() => setDirectionsFiltesModalOpen(false)}
          filters={filters}
          onSubmit={async (val: any) => {
            setDirectionsFilter(val.directions || []);
          }}
          triggerRef={filterBtnRef.current!}
          initialData={{ directions: directionsFilter }}
        />
      )}
    </Styled.Leaderboards>
  );
};

export default Leaderboards;
