import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';
import { PageLayout } from '../../components';

export const HeaderBar = styled.div`
  ${flex('column', 'center', 'stretch')};

  .kinto-community-element {
    font-size: ${theme.sizes.SIZE_16};
  }
`;

export const BookingPage = styled(PageLayout)`
  .bookings-page {
    &__header-actions {
      ${flex('row')}
      gap: ${theme.sizes.SIZE_32};
      margin: ${theme.sizes.SIZE_16} 0 ${theme.sizes.SIZE_16} auto;
      color: var(--SHADE_1_2);
    }

    &__empty-state {
      ${flex('column')}
      margin: auto;
      margin-top: ${theme.sizes.SIZE_104};

      &__title {
        font-size: ${theme.fontSizes.FONT_30};
        font-weight: 600;
        margin-top: ${theme.sizes.SIZE_40};
        margin-bottom: 0;
        text-align: center;
        color: var(--SECONDARY_1_1);
      }

      &__subtitle {
        color: var(--SHADE_1_2);
        margin-top: ${theme.sizes.SIZE_12};
        text-align: center;
      }
    }
  }
`;
